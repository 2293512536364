import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { useHistory } from 'react-router';
import Lightbox from 'yet-another-react-lightbox';
import {Link} from 'react-router-dom';
import Layout from '../../components/Layout';
import Spinner from '../../components/Common/Spinner';
import ConfirmDialog from '../../components/Common/ComfirmDialog';
import { parseDate } from '../../utils';
import { useSelector } from 'react-redux';
import { DescriptionOutlined } from '@material-ui/icons';

import './style.scss';

const DisputeDetailPage = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState();
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const history = useHistory();
    const bkdDriver = useSelector((state) => state.driverObject.bkdDriver);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const getDisputeDetail = async () => {
        if (bkdDriver?.headers) {
            setIsLoading(true);
            const id = history.location.pathname.split('/disputes/')[1];
            const res = await bkdDriver.getDisputeById(id);
            setData(res);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getDisputeDetail();
    }, [history.location.pathname, bkdDriver]);

    const cancelDispute = () => {
        setIsLoading(true);
        setIsOpenConfirmDialog(false);
        setTimeout(() => {
            setIsLoading(false);
            history.push('/disputes');
        }, 5000);
    };

    const disputeWithDraw = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            history.push('/home');
        }, 5000);
    };

    return (
        <Layout title="Dispute">
            {data && (
                <div className="dispute-detail-page">
                    <div className="block">
                        <div className="status">
                            <div
                                className={`status-btn ${data.status.toLowerCase()}`}
                            >
                                {data.status}
                            </div>
                        </div>

                        <div className="main-info">
                            <div className="product-image">
                                <img
                                    src={data.order.product.image}
                                    alt=""
                                />
                            </div>

                            <div className="product-name">
                                <p>{data.order.product.name}</p>
                                <p>
                                    <strong>Category: </strong>
                                    {data.order.product.category?.name}
                                </p>
                                <p>
                                    <strong>Price: </strong>
                                    {data.order.product.price} MTO
                                </p>
                            </div>

                            <div className="info">
                                <p className="label">Dispute Created At:</p>
                                <p>{parseDate(data.createdAt)}</p>
                            </div>

                            <div className="info">
                                <p className="label">Purchased At</p>
                                <p>{parseDate(data.order.createdAt)}</p>
                            </div>
                        </div>

                        <div className="info">
                            <p className="label">Dispute Description</p>
                            <p>{data.description}</p>
                        </div>
                        <div className="info align-items-start">
                            <div className="info-block">
                                <div className="info-item">
                                    <p className="label">Buyer Info:</p>
                                    <div className="merchant-info">
                                        <Person />
                                        <span>{data.order.customer?.name}</span>
                                    </div>
                                </div>
                                <div className="info-item">
                                    <span>Email:</span>
                                    <span>{data.order.customer?.email}</span>
                                </div>
                                <div className="info-item">
                                    <span>Address:</span>
                                    <span>{data.order.customer?.shippingAddress}</span>
                                </div>
                                <div className="info-item">
                                    <span>Link:</span>
                                    <a
                                        href={data.order.customer?.externalLink}
                                        target="_blank"
                                        className="merchant-name"
                                    >
                                        {data.order.customer?.externalLink}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="info align-items-end">
                            <div className="info-block">
                                <div className="info-item">
                                    <span>Delivery period:</span>
                                    <span>{parseDate(data.order.deliveryTime)}</span>
                                </div>

                                <div className="info-item">
                                    <span>Escrow period:</span>
                                    <span>{parseDate(data.order.escrowTime)}</span>
                                </div>
                            </div>
                            {(data.status === 'Init' ||
                                data.status === 'Waiting' ||
                                data.status === 'Review') && (
                                <div className="info-block">
                                    <div className="info-item">
                                        <span>Agents in Review:</span>
                                        <span>{data.agentsInReview}</span>
                                    </div>

                                    <div className="info-item">
                                        <span>Agents in Approved:</span>
                                        <span>{data.agentsInApproved}</span>
                                    </div>

                                    <div className="info-item">
                                        <span>Agents in Disapproved:</span>
                                        <span>{data.agentsInDisapproved}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        {data.status === 'Fail' && (
                            <p className="help-text">
                                <i>
                                    Sorry, you got failed for this case. Buyer will
                                    take funds back from an escrow pool.
                                </i>
                            </p>
                        )}

                        {data.status === 'Win' && (
                            <p className="help-text">
                                <i>
                                    Congrats! You have won a dispute.
                                    <br />
                                    Please withdraw the funds from an escrow
                                    contract.
                                </i>
                            </p>
                        )}

                        <div className="actions">
                            {data.status === 'Win' && (
                                <Button
                                    className="win"
                                    color="black"
                                    variant="contained"
                                    onClick={disputeWithDraw}
                                >
                                    Withdraw
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header block-header-default">
                            <div className="block-title">Shared files</div>
                        </div>
                        <div className="block-content">
                            
                            <div className="info">
                                {/* <p className="label">Shared files:</p> */}
                                {
                                data?.disputeFiles?.length > 0 ? (
                                    <div className='preview'>
                                        {
                                            data?.disputeFiles.map((file, index) => (
                                                file.fileType?.includes("image/") || file.type?.includes("image/") ?(
                                                    <div className="preview-file" onClick={() =>  {
                                                        console.log('image clicked');
                                                        setIsLightboxOpen(true)
                                                    }}>
                                                        <img
                                                            key={index}
                                                            src={file?.url}
                                                            alt=""
                                                        />
                                                    </div>
                                                ) : (
                                                    <Link to={{pathname: file?.url}} target="_blank">
                                                        <div className="preview-file">
                                                            <DescriptionOutlined/>
                                                        </div>
                                                    </Link>
                                                )
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <p>No file shared</p>
                                )
                                }
                            </div>
                           
                        </div>
                    </div>

                    {isLoading && (
                        <div className="overlay">
                            <Spinner />
                        </div>
                    )}
                </div>
            )}

            <ConfirmDialog
                open={isOpenConfirmDialog}
                handler={cancelDispute}
                content="Are you sure to cancel this dispute?"
                onClose={() => setIsOpenConfirmDialog(false)}
            />
            <Lightbox
                open={isLightboxOpen}
                close={() => setIsLightboxOpen(false)}
                slides={data?.disputeFiles?.filter(item => item.fileType.includes("image/")).map(item => ({ src:item?.url }))}
            />
        </Layout>
    );
};

export default DisputeDetailPage;
