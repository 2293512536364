export const SET_BKD_DRIVER = 'SET_BKD_DRIVER';
export const SET_SC_DRIVER = 'SET_SC_DRIVER';
export const SET_WEB3_STATE = 'SET_WEB3_STATE';
export const CLEAR_WEB3_STATE = 'SET_WEB3_STATE';

// Auth Types
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_LOADER = 'SET_USER_DATA_LOADER';
export const SET_USER_NFTS = 'SET_USER_NFTS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_EXPLORE_NFTS = 'SET_EXPLORE_NFTS';

export const Web_3_Object = "Web_3_Object";
export const WEB_3_CONNECTED = "WEB_3_CONNECTED";
export const SET_NETWORK = "SET_NETWORK"

export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_SELECTED_TYPE = "SET_SELECTED_TYPE";
export const SET_META_MASK_ADDRESS = "SET_META_MASK_ADDRESS";
export const DELETE_META_MASK_ADDRESS = "DELETE_META_MASK_ADDRESS";


//profile reducer
export const SET_PROFILE_NFTS = "SET_PROFILE_NFTS";
export const SET_SELECTED_PAGE_PROFILE = "SET_SELECTED_PAGE_PROFILE";
export const SET_SELECTED_TYPE_PROFILE = "SET_SELECTED_TYPE_PROFILE";
// JWT TYPE
export const SET_CHAT_JWT = "SET_CHAT_JWT";
export const SET_MAIN_JWT = "SET_CHAT_JWT";

export const SET_TOKEN_PRICE = "SET_TOKEN_PRICE";
export const SET_WOM_TO_ETH_RATE = "SET_WOM_TO_ETH_RATE";