export const generateSignature = () => 'wallet_address';

export const getAddress = () => 'wallet_address';

export const parseDate = (d) => {
    let date = new Date(d).getDate();
    let month = new Date(d).getMonth() + 1;
    let year = new Date(d).getFullYear();

    let hours = new Date(d).getHours();
    let minutes = new Date(d).getMinutes();
    return `${date}/${month}/${year} ${hours}:${minutes}`;
};

export const parseDateWithoutTimeZone = (d) => {
    const parsedDate = new Date(d);
    let date = parsedDate.getUTCDate();
    let month = parsedDate.getUTCMonth() + 1;
    let year = parsedDate.getUTCFullYear();

    let hours = parsedDate.getUTCHours();
    let minutes = parsedDate.getUTCMinutes();
    return `${date}/${month}/${year} ${hours}:${minutes}`;
};
