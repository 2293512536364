import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { parseDate } from '../../../utils/index';

import './style.scss';
import { useSelector } from 'react-redux';

const ProductsTable = ({
    products,
    columns,
    onRowClick,
    page,
    setPage,
    pageSize,
    setPageSize,
    keyword,
    setKeyword,
    totalCount,
    categoryId,
    setCategoryId,
}) => {
    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };
    const categories = useSelector((state) => state.auth.categories);
    const [selectedCategory, setSelectedCategory] = useState({});
    const handleChangePageSize = (event) => {
        // setPageSize(+event.target.value + 1);
        // setPage(1);
        setPage(1);
        setPageSize(event.target.value);
    };

    useEffect(() => {
        if (categories) {
            categories.splice(0, 0, {id: -1, name: 'All'});
            // selectedCategory({value: -1, name: 'All'})
        }
    }, [categories]);

    return (
        <Paper className="product-table">
            <TableContainer className="table-container">
                <div className="table-header">
                    <div className="search-input">
                        <Search />
                        <input
                            type="text"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder="Search..."
                        />
                    </div>
                    <FormControl style={{width: 120 }}>
                        <InputLabel id="demo-simple-select-label">
                            Category
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="category"
                            value={selectedCategory?.value}
                            label="Category"
                            onChange={(ev) => {
                                setSelectedCategory(ev.target);
                                setCategoryId(ev.target.value);
                            }}
                        >
                            {categories?.map((item) => (
                                <MenuItem
                                    value={item.id}
                                    name={item.name}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <p className="total-cnt">
                        Total Products
                        <span style={{ margin: '0 4px' }}>:</span>
                        <strong>{totalCount}</strong>
                    </p>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell className="table-cell" key={index}>
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((product) => (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={product.id}
                                onClick={() => onRowClick(product.id)}
                            >
                                {columns.map((column, index) => {
                                    const value = product[column.key];
                                    return (
                                        <TableCell
                                            className="table-cell"
                                            key={index}
                                        >
                                            {column.key === 'image' ? (
                                                <img src={value} alt="" />
                                            ) : column.key === 'createdAt' ? (
                                                <span>{parseDate(value)}</span>
                                            ) : column.key === 'category' ? (
                                                <span>{value.name}</span>
                                            ) : (
                                                <span>{value || 'N/A'}</span>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={pageSize}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangePageSize}
            />
        </Paper>
    );
};

ProductsTable.propTypes = {
    products: PropTypes.array,
    columns: PropTypes.array,
    onRowClick: PropTypes.func,
    page: PropTypes.number,
    setPage: PropTypes.func,
    pageSize: PropTypes.number,
    setPageSize: PropTypes.func,
    keyword: PropTypes.string,
    setKeyword: PropTypes.func,
    totalCount: PropTypes.number,
    categoryId: PropTypes.number,
    setCategoryId: PropTypes.func,
};

ProductsTable.defaultProps = {
    products: [],
    columns: [],
    onRowClick: () => {},
    page: 1,
    setPage: () => {},
    pageSize: 5,
    setPageSize: () => {},
    keyword: '',
    setKeyword: () => {},
    totalCount: 0,
    categoryId: -1
};

export default ProductsTable;
