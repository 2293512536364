/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';

import './style.scss';

const ErrorDialog = ({ open, onClose, message }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogContent>
            <div className="dialog-header">Error!</div>
            <p>{message}</p>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>
                ok
            </Button>
        </DialogActions>
    </Dialog>
);

ErrorDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    message: PropTypes.string,
};

ErrorDialog.defaultProps = {
    open: false,
    onClose: () => {},
    message: '',
};

export default ErrorDialog;
